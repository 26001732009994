import { useTranslation } from "react-i18next";
import Checkbox from "../Inputs/Checkbox/Checkbox";
import Slider from "../Inputs/Slider/Slider";
import { useDispatch, useSelector } from "react-redux";
import {
  handleStateChange,
  handleStateChangeMultiple,
} from "../../../../data/slices/filtersSlice";

const Facilities = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    is_kitchen_sink_installed,
    palm,
    palm_range,
    is_cabinet_installed,
    fruitful_trees,
    wood_stove,
    ornamental_trees,
    ornamental_trees_range,
    central_air_conditioner_range,
    window_air_conditioner_range,
    desert_cooler_range,
    central_air_conditioner,
    window_air_conditioner,
    desert_cooler,
    split_unit,
    split_unit_range,
    fruitful_trees_range,
    private_elevator,
    shared_elevator,
  } = useSelector((state) => state.filters);

  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
  };

  const handleBtnClickMultiple = (target, value) => {
    dispatch(
      handleStateChangeMultiple({
        target,
        value,
      })
    );
  };

  const { specifications, default_values } = useSelector(
    (state) => state.properties
  );



  return (
    <div className="facilities-filters">
     
        <div className="smaller-content-container">
          <div className="btns-list">
            <p className="label">{t("filters.Central_AC")}</p>
            <button
              className={`btn ${
                central_air_conditioner?.includes("yes") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("central_air_conditioner", "yes")
              }
            >
              {t("filters.With_central_aC")}
            </button>
            <button
              className={`btn ${
                central_air_conditioner?.includes("no") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("central_air_conditioner", "no")
              }
            >
              {t("filters.without_central_AC")}
            </button>
          </div>
          {central_air_conditioner?.includes("yes") && (
            <div className="smaller-content-container">
              <p className="label">{t("filters.Number_of_central_ACs")}</p>
              <Slider
                min={default_values?.central_air_conditioner?.[0] ?? 0}
                max={default_values?.central_air_conditioner?.[1] ?? 0}
                values={[
                  central_air_conditioner_range?.split("-")[0],
                  central_air_conditioner_range?.split("-")[1],
                ]}
                target="central_air_conditioner_range"
                unit=""
              />
            </div>
          )}
        </div>
      

        <div className="smaller-content-container">
          <div className="btns-list">
            <p className="label">{t("filters.Split_AC")}</p>
            <button
              className={`btn ${split_unit?.includes("yes") && "btn-active"}`}
              onClick={() => handleBtnClickMultiple("split_unit", "yes")}
            >
              {t("filters.With_split_aC")}
            </button>
            <button
              className={`btn ${split_unit?.includes("no") && "btn-active"}`}
              onClick={() => handleBtnClickMultiple("split_unit", "no")}
            >
              {t("filters.without_split_AC")}
            </button>
          </div>
          {split_unit?.includes("yes") && (
            <div className="smaller-content-container">
              <p className="label">{t("filters.Number_of_split_ACs")}</p>
              <Slider
                min={1}
                max={5}
                values={[
                  split_unit_range?.split("-")[0],
                  split_unit_range?.split("-")[1],
                ]}
                target="split_unit_range"
                unit=""
              />
            </div>
          )}
        </div>
      

        <div className="smaller-content-container">
          <div className="btns-list">
            <p className="label">{t("filters.Window_AC")}</p>
            <button
              className={`btn ${
                window_air_conditioner?.includes("yes") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("window_air_conditioner", "yes")
              }
            >
              {t("filters.With_window_aC")}
            </button>
            <button
              className={`btn ${
                window_air_conditioner?.includes("no") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("window_air_conditioner", "no")
              }
            >
              {t("filters.without_window_AC")}
            </button>
          </div>
          {window_air_conditioner?.includes("yes") && (
            <div className="smaller-content-container">
              <p className="label">{t("filters.Number_of_window_ACs")}</p>
              <Slider
                min={default_values?.window_air_conditioner?.[0] ?? 0}
                max={default_values?.window_air_conditioner?.[1] ?? 0}
                values={[
                  window_air_conditioner_range?.split("-")[0],
                  window_air_conditioner_range?.split("-")[1],
                ]}
                target="window_air_conditioner_range"
                unit=""
              />
            </div>
          )}
        </div>
      

        <div className="smaller-content-container">
          <div className="btns-list">
            <p className="label">{t("filters.Desert_AC")}</p>
            <button
              className={`btn ${
                desert_cooler?.includes("yes") && "btn-active"
              }`}
              onClick={() => handleBtnClickMultiple("desert_cooler", "yes")}
            >
              {t("filters.With_desrt_aC")}
            </button>
            <button
              className={`btn ${desert_cooler?.includes("no") && "btn-active"}`}
              onClick={() => handleBtnClickMultiple("desert_cooler", "no")}
            >
              {t("filters.without_desert_AC")}
            </button>
          </div>
          {desert_cooler?.includes("yes") && (
            <div className="smaller-content-container">
              <p className="label">{t("filters.Number_of_desert_ACs")}</p>
              <Slider
                min={default_values?.desert_cooler?.[0] ?? 0}
                max={default_values?.desert_cooler?.[1] ?? 0}
                values={[
                  desert_cooler_range?.split("-")[0],
                  desert_cooler_range?.split("-")[1],
                ]}
                target="desert_cooler_range"
                unit=""
              />
            </div>
          )}
        </div>

        <div className="smaller-content-container">
          <div className="btns-list">
            <p className="label">{t("filters.Palm")}</p>
            <button
              className={`btn ${palm?.includes("yes") && "btn-active"}`}
              onClick={() => handleBtnClickMultiple("palm", "yes")}
            >
              {t("filters.With_Palm")}
            </button>
            <button
              className={`btn ${palm?.includes("no") && "btn-active"}`}
              onClick={() => handleBtnClickMultiple("palm", "no")}
            >
              {t("filters.without_Palm")}
            </button>
          </div>
          {palm?.includes("yes") && (
            <div className="smaller-content-container">
              <p className="label">{t("filters.Number_of_Palm")}</p>
              <Slider
                min={default_values?.palm?.[0] ?? 0}
                max={default_values?.palm?.[1] ?? 0}
                values={[palm_range?.split("-")[0], palm_range?.split("-")[1]]}
                target="palm_range"
                unit=""
              />
            </div>
          )}
        </div>
      

        <div className="smaller-content-container">
          <div className="btns-list">
            <p className="label">{t("filters.ornamental_trees")}</p>
            <button
              className={`btn ${
                ornamental_trees?.includes("yes") && "btn-active"
              }`}
              onClick={() => handleBtnClickMultiple("ornamental_trees", "yes")}
            >
              {t("filters.With_ornamental_trees")}
            </button>
            <button
              className={`btn ${
                ornamental_trees?.includes("no") && "btn-active"
              }`}
              onClick={() => handleBtnClickMultiple("ornamental_trees", "no")}
            >
              {t("filters.without_ornamental_trees")}
            </button>
          </div>
          {ornamental_trees?.includes("yes") && (
            <div className="smaller-content-container">
              <p className="label">{t("filters.Number_of_ornamental_trees")}</p>
              <Slider
                min={default_values?.ornamental_trees?.[0] ?? 0}
                max={default_values?.ornamental_trees?.[1] ?? 0}
                values={[
                  ornamental_trees_range?.split("-")[0],
                  ornamental_trees_range?.split("-")[1],
                ]}
                target="ornamental_trees_range"
                unit=""
              />
            </div>
          )}
        </div>
      

        <div className="smaller-content-container">
          <div className="btns-list">
            <p className="label">{t("filters.fruitful_trees")}</p>
            <button
              className={`btn ${
                fruitful_trees?.includes("yes") && "btn-active"
              }`}
              onClick={() => handleBtnClickMultiple("fruitful_trees", "yes")}
            >
              {t("filters.With_fruitful_trees")}
            </button>
            <button
              className={`btn ${
                fruitful_trees?.includes("no") && "btn-active"
              }`}
              onClick={() => handleBtnClickMultiple("fruitful_trees", "no")}
            >
              {t("filters.without_fruitful_trees")}
            </button>
          </div>
          {fruitful_trees?.includes("yes") && (
            <div className="smaller-content-container">
              <p className="label">{t("filters.Number_of_fruitful_trees")}</p>
              <Slider
                min={default_values?.fruitful_trees?.[0] ?? 0}
                max={default_values?.fruitful_trees?.[1] ?? 0}
                values={[
                  fruitful_trees_range?.split("-")[0],
                  fruitful_trees_range?.split("-")[1],
                ]}
                target="fruitful_trees_range"
                unit=""
              />
            </div>
          )}
        </div>
      
      <div className="checkbox-container">
     
          <Checkbox
            label={t("filters.Installed_kitchen")}
            checked={is_kitchen_sink_installed === "yes" ? true : false}
            onClick={() =>
              handleBtnClick(
                "is_kitchen_sink_installed",
                is_kitchen_sink_installed === "yes" ? "no" : "yes"
              )
            }
          />
        
          <Checkbox
            label={t("filters.Installed_cabinet")}
            checked={is_cabinet_installed === "yes" ? true : false}
            onClick={() =>
              handleBtnClick(
                "is_cabinet_installed",
                is_cabinet_installed === "yes" ? "no" : "yes"
              )
            }
          />
        
      </div>

      <div className="checkbox-container">
          <Checkbox
            label={t("filters.private_elevator")}
            checked={private_elevator === "yes" ? true : false}
            onClick={() =>
              handleBtnClick(
                "private_elevator",
                private_elevator === "yes" ? "no" : "yes"
              )
            }
          />
       
          <Checkbox
            label={t("filters.shared_elevator")}
            checked={shared_elevator === "yes" ? true : false}
            onClick={() =>
              handleBtnClick(
                "shared_elevator",
                shared_elevator === "yes" ? "no" : "yes"
              )
            }
          />
        
      </div>

      <div className="checkbox-container">
       
          <Checkbox
            label={t("filters.Wood_stove")}
            checked={wood_stove === "yes" ? true : false}
            onClick={() =>
              handleBtnClick("wood_stove", wood_stove === "yes" ? "no" : "yes")
            }
          />
        
      </div>
    </div>
  );
};

export default Facilities;
