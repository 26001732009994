import React from "react";
import "./_ImportantInfo.scss";
import Specifications from "../../../PropertyDetails/components/Specifications/specifications";
import RoomsAndOthers from "../../../PropertyDetails/components/RoomsAndOthers/RoomsAndOthers";
import Services from "../../../PropertyDetails/components/Services/Services";
import Facilities from "../../../PropertyDetails/components/Facilities/Facilities";
import Fourniture from "../../../PropertyDetails/components/Fourniture/Fourniture";
import Tag from "../Tag/Tag";
import no_smoking_Icon from "../../assets/icons/no_smoking.svg";
import { useTranslation } from "react-i18next";

function ImportantInfo({ importantInfo, property }) {
  const { t } = useTranslation();

  return (
    <div className="important_info">
      {importantInfo?.specifications_array && (
        <Specifications
          items={importantInfo?.specifications_array}
          type="important"
        />
      )}
      {importantInfo?.rooms_array && (
        <RoomsAndOthers
          items={importantInfo?.rooms_array || {}}
          type="important"
        />
      )}
      {importantInfo?.services_array && (
        <Services items={importantInfo?.services_array} type="important" />
      )}
      {importantInfo?.specifications_facilities_array && (
        <Facilities
          items={importantInfo?.specifications_facilities_array}
          type="important"
        />
      )}
      {importantInfo?.furniture_array && (
        <Fourniture items={importantInfo?.furniture_array} type="important" />
      )}
      {property?.offer_type === "daily_rent" &&
        property?.rent_specification?.smoking_prohibited === "yes" && (
          <Tag
            title={
              property?.rent_specification?.smoking_prohibited === "yes" &&
              t("daily_rent.No_Smoking")
            }
            background="1b1c57"
            icon={no_smoking_Icon}
          />
        )}
    </div>
  );
}

export default ImportantInfo;
