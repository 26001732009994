import React, { useRef, useState } from "react";
import "./_ResponsiveSlider.scss";
import arrRight from "../../assets/icons/arrRight.svg";
import arrLeft from "../../assets/icons/arrleft.svg";

const ResponsiveSlider = (props) => {
  const containerRef = useRef();

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    e.preventDefault();

    setIsMouseDown(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();

    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the sensitivity here
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleScroll = (direction) => {
    const container = containerRef?.current;

    if (container) {
      container.scrollLeft += direction === "left" ? -150 : 150;
    }
  };
  return (
    <div
      className="tag-slider"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div
        className="arrow left"
        onClick={() =>
          handleScroll(document.body.dir === "rtl" ? "right" : "left")
        }
      >
        <img src={document.body.dir === "rtl" ? arrRight : arrLeft} alt="" />{" "}
      </div>
      <div className="tags" ref={containerRef}>
        {props.children}
      </div>

      <div
        className="arrow right"
        onClick={() =>
          handleScroll(document.body.dir === "rtl" ? "left" : "right")
        }
      >
        <img src={document.body.dir === "rtl" ? arrLeft : arrRight} alt="" />{" "}
      </div>
    </div>
  );
};

export default ResponsiveSlider;
