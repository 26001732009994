import React, { useState } from "react";
import "./_DailyRentPrices.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Checkbox from "../Inputs/Checkbox/Checkbox";
import Slider from "../Inputs/Slider/Slider";
import "./_DailyRentPrices.scss";
import { handleStateChange } from "../../../../data/slices/filtersSlice";
import DateRange from "../../../../components/DateRange/DateRange";
import dayjs from "dayjs";
import NewDateRange from "../../../../components/DateRange/NewRangeSelector";
function DailyRentPrices() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    daily_price_range,
    daily_total_range,
    smoking_allowed,
    daily_from_date,
    daily_to_date,
  } = useSelector((state) => state.filters);
  const { default_values, currency_icon } = useSelector(
    (state) => state.properties
  );
  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
  };
  return (
    <div>
      <>
        <div className="price_daily">
          <p className="label">{t("daily_rent.price_of_one_day")}</p>
          <Slider
            min={default_values?.price_per_daily?.[0] ?? 0}
            max={default_values?.price_per_daily?.[1] ?? 0}
            values={[
              daily_price_range?.split("-")[0],
              daily_price_range?.split("-")[1],
            ]}
            target="daily_price_range"
            unit={currency_icon}
          />
        </div>
        <div className="price_daily">
          <p className="label">{t("daily_rent.Total Of All days Price")}</p>
          <Slider
            min={default_values?.price_total_daily?.[0] ?? 0}
            max={default_values?.price_total_daily?.[1] ?? 0}
            values={[
              daily_total_range?.split("-")[0],
              daily_total_range?.split("-")[1],
            ]}
            target="daily_total_range"
            unit={currency_icon}
          />
        </div>
        <div className="checkbox-container">
          <Checkbox
            label={t("daily_rent.No_Smoking")}
            checked={smoking_allowed === "no" ? true : false}
            onClick={() => handleBtnClick("smoking_allowed", "no")}
          />
          <Checkbox
            label={t("daily_rent.Smoking_Allowed")}
            checked={smoking_allowed === "yes" ? true : false}
            onClick={() => handleBtnClick("smoking_allowed", "yes")}
          />
        </div>
        <div className="btns-list">
          <button
            className={
              daily_from_date === dayjs(Date.now()).format("YYYY-MM-DD")
                ? `btn btn-active`
                : "btn"
            }
            onClick={() => {
              handleBtnClick(
                "daily_from_date",
                dayjs(Date.now()).format("YYYY-MM-DD")
              );
              handleBtnClick("daily_to_date", null);
            }}
          >
            {t("daily_rent.Available_today")}
          </button>
          {/* <DateRange /> */}
          <NewDateRange />
          {/* <p className="num-days">{ nbDays}</p> */}
        </div>
      </>
    </div>
  );
}

export default DailyRentPrices;
