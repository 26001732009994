import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import cookies from "js-cookie";

const lang = cookies.get("i18next") || "en";

const filterPayload = (specifications, payload) => {
  const updatedPayload = { ...payload };

  Object.keys(payload).forEach((key) => {
    if (specifications[key] === "0") {
      delete updatedPayload[key];
    }
  });

  return updatedPayload;
};

const initialState = {
  properties: [],
  home_data: [],
  property_types: [],
  property: null,
  specifications: [],
  filters: null,
  //default_values =>  for each slider needs a max and min value for example
  default_values: [],
  error: null,
  message: null,
  loading: "idle",
  currency_icon: null,
  currency_key: "",
  property_types_loading: "idle",
};

export const getProperties = createAsyncThunk(
  "api/properties",
  async ({ lang, body }, { getState }) => {
    let data;
    if (body.sa_id) {
      body.sa_id = body.sa_id.replace("l", "");
    }

    let newBody = { ...body, offer: body?.offer_type };
    delete newBody.offer_type;
    const specifications = getState().properties.specifications;

    let cleanBody = filterPayload(specifications, newBody);
    try {
      const response = await axiosInstance.post(`/properties?lang=${lang}`, {
        ...cleanBody,
      });
      data = await response.data;
      console.log(data)
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const getBrokerProperties = createAsyncThunk(
  "api/get_broker_properties",
  async ({ lang, body }, { getState }) => {
    let data;
    if (body.sa_id) {
      body.sa_id = body.sa_id.replace("l", "");
    }

    let newBody = { ...body, offer: body?.offer_type };
    delete newBody.offer_type;
    const specifications = getState().properties.specifications;

    let cleanBody = filterPayload(specifications, newBody);
    try {
      const response = await axiosInstance.post(`/get_broker_properties?lang=${lang}`, {
        ...cleanBody,
      });
      data = await response.data;
      console.log(data)
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const getRecentProperties = createAsyncThunk(
  "api/recent-properties",
  async ({ lang, body }) => {
    let data;
    try {
      const response = await axiosInstance.post(`/index?lang=${lang}`, {
        ...body,
      });
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const getpropertytypes = createAsyncThunk(
  "api/get_property_types",
  async ({ lang, country_id, country_code }) => {
    let str = `country_code=${country_code}`;
    if (country_id) {
      str = `country_id=${country_id}`;
    }
    let data;
    try {
      const response = await axiosInstance.get(
        `/home/get_property_types?lang=${lang}&${str}`
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
// get one property
export const getPropertyByID = createAsyncThunk(
  "api/property-id",
  async ({ body, lang }) => {
    let data;
    try {
      const response = await axiosInstance.post(
        `/property_detail?lang=${lang}`,
        {
          ...body,
        }
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const addproperty = createAsyncThunk(
  "user/addproperty",
  async ({ lang, body }) => {
    let data;
    try {
      const response = await axiosInstance.post(
        `/user/addproperty?lang=${lang}`,
        {
          ...body,
        }
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
export const verifyProperty = createAsyncThunk(
  "user/verifyProperty",
  async (payload) => {
    const { code, name, email, phone, wphone, lat_long } = payload;
    let data;
    try {
      const response = await axiosInstance.get(
        `/home/verifyproperty?email=${email}&code=${code}`
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
export const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    setSpecifications: (state, action) => {
      state.specifications = action.payload;
    },
    setDefaultValues: (state, action) => {
      state.default_values = action.payload;
    },
    setProperties: (state, action) => {

      state.properties = action.payload;
    }
  },
  extraReducers: {
    [getProperties.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getProperties.fulfilled]: (state, action) => {
      const {
        properties,
        specifications,
        filter,
        currency_icon,
        currency_key,
      } = action.payload;
      state.properties = properties;
      state.specifications = specifications;
      state.default_values = filter;
      state.currency_key = currency_key;
      state.currency_icon = currency_icon;
      state.loading = "idle";
    },
    [getProperties.rejected]: (state, action) => {
      state.error = action.error.message;
      state.properties = []
    },
    [getBrokerProperties.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getBrokerProperties.fulfilled]: (state, action) => {
      const {
        properties,
        specifications,
        filter,
        currency_icon,
        currency_key,
      } = action.payload;
      state.properties = properties;
      state.specifications = specifications;
      state.default_values = filter;
      state.currency_key = currency_key;
      state.currency_icon = currency_icon;
      state.loading = "idle";
    },
    [getBrokerProperties.rejected]: (state, action) => {
      state.error = action.error.message;
      state.properties = []
    },
    //get recent properties
    [getRecentProperties.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getRecentProperties.fulfilled]: (state, action) => {
      const data = action.payload;
      state.home_data = data;
      state.loading = "idle";
    },
    [getRecentProperties.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getpropertytypes.pending]: (state) => {
      state.error = null;
      state.property_types_loading = "loading";
    },
    [getpropertytypes.fulfilled]: (state, action) => {
      const data = action.payload;
      state.property_types = data;
      state.property_types_loading = "idle";
    },
    [getpropertytypes.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //get one property
    [getPropertyByID.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getPropertyByID.fulfilled]: (state, action) => {
      const data = action?.payload?.properties;
      if (data) {
        state.property = data?.[0];
      }
      state.loading = "idle";
    },
    [getPropertyByID.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const { setSpecifications, setDefaultValues, setProperties } = propertiesSlice.actions;
// export const specifications = (state) => ...

export default propertiesSlice.reducer;
