import { Avatar } from "@mui/material";
import useWindowSize from "../../hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import * as React from "react";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { logout } from "../../data/slices/authSlice";
import { ReactComponent as ArrDown } from "../../assets/icons/ArrDown.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logout } from "../../assets/icons/darklogout.svg";
import "./_Menu.scss";
const MenuHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (width < 1090) {
    return null;
  }

  return (
    <div className="menu">
      <div className="avatar" onClick={handleClick}>
        <Avatar
          className="profile__avatar"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        />
        <ArrDown />
      </div>

      {anchorEl && width > 1090 && (
        <Menu
          className="menu_main"
          id="basic-menu1"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {user?.usertype != "customer" ? (
            <MenuItem
              // className="menu_item"
              onClick={() => {
                handleClose();
                navigate("/profile/org");
              }}
              selected={false}
            >
              {t("sidebar.Company_information")}
            </MenuItem>
          ) : (
            <MenuItem
              // className="menu_item"
              onClick={() => {
                handleClose();
                navigate("/profile/user");
              }}
              selected={false}
            >
              {t("sidebar.personal_information")}
            </MenuItem>
          )}
          {user?.usertype === "selling_agent" ? (
            <MenuItem
              // className="menu_item"
              onClick={() => {
                handleClose();
                navigate("/management-sale-org");
              }}
              selected={false}
            >
              {t("sidebar.manage_properties_company")}
            </MenuItem>
          ) : (
            <MenuItem
              // className="menu_item"
              onClick={() => {
                handleClose();
                navigate("/management-sale-org");
              }}
              selected={false}
            >
              {/* //manage_properties_company */}
              {t("sidebar.Manage_properties")}
            </MenuItem>
          )}
          <MenuItem
            // className="menu_item"
            onClick={() => {
              handleClose();
              navigate("/favorites");
            }}
            selected={false}
          >
            {t("sidebar.Favorites")}
          </MenuItem>
          <MenuItem onClick={() => dispatch(logout())}>
            {t("sidebar.logout")} <Logout />
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default MenuHeader;
