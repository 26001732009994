import { useTranslation } from "react-i18next";
import Checkbox from "../Inputs/Checkbox/Checkbox";
import Slider from "../Inputs/Slider/Slider";
import { useDispatch, useSelector } from "react-redux";
import { handleStateChange } from "../../../../data/slices/filtersSlice";
import DailyRentPrices from "../DailyRentPricesFilters/DailyRentPrices";

const Pricing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { specifications, default_values, currency_icon } = useSelector(
    (state) => state.properties
  );

  const {
    minprice,
    maxprice,
    price_per_meter_monthly_range,
    price_per_meter_yearly_range,
    price_per_meter,
    toll_price_per_meter_range,
    sell_available,
    toll_price,
    toll_available,
    payment_method,
  } = useSelector((state) => state.filters);
  const filters = useSelector((state) => state.filters);

  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
  };

  return (
    <div className="pricing-filters">
      {filters?.offer_type === "sell" ? (
        <>
          <p className="label">{t("filters.Selling_Price")}</p>
          <div className="price-determined-container">
            <Checkbox
              label={t("filters.Not_Determined")}
              checked={sell_available === "no" ? true : false}
              onClick={() => handleBtnClick("sell_available", "no")}
            />
            <Checkbox
              label={t("filters.Determined_price")}
              checked={sell_available === "yes" ? true : false}
              onClick={() => handleBtnClick("sell_available", "yes")}
            />
          </div>

          <Slider
            min={default_values?.sell_price?.[0] ?? 0}
            max={default_values?.sell_price?.[1] ?? 0}
            values={[minprice, maxprice]}
            target={["minprice", "maxprice"]}
            unit={currency_icon}
          />

          <p className="label">{t("filters.Per_meter")}</p>
          <Slider
            min={default_values?.price_per_meter?.[0] ?? 0}
            max={default_values?.price_per_meter?.[1] ?? 0}
            values={[
              price_per_meter?.split("-")[0],
              price_per_meter?.split("-")[1],
            ]}
            target="price_per_meter_range"
            unit={currency_icon}
          />

          <hr />

          <p className="label">{t("filters.Toll_Price")} </p>
          <div className="price-determined-container">
            <Checkbox
              label={t("filters.Not_available")}
              checked={toll_available === "no" ? true : false}
              onClick={() => handleBtnClick("toll_available", "no")}
            />
            <Checkbox
              label={t("filters.Available")}
              checked={toll_available === "yes" ? true : false}
              onClick={() => handleBtnClick("toll_available", "yes")}
            />
          </div>

          <Slider
            min={default_values?.toll_price?.[0] ?? 0}
            max={default_values?.toll_price?.[1] ?? 0}
            values={[toll_price?.split("-")[0], toll_price?.split("-")[1]]}
            target="toll_price"
            unit={currency_icon}
          />

          <p className="label">{t("filters.Per_meter")}</p>
          <Slider
            min={default_values?.toll_price_per_meter?.[0] ?? 0}
            max={default_values?.toll_price_per_meter?.[1] ?? 0}
            values={[
              toll_price_per_meter_range?.split("-")[0],
              toll_price_per_meter_range?.split("-")[1],
            ]}
            target="toll_price_per_meter_range"
            unit={currency_icon}
          />
        </>
      ) : (
        filters?.offer_type === "rent" && (
          <>
            <p className="label">{t("filters.Monthly rent")}</p>
            <Slider
              min={default_values?.price_per_meter_monthly?.[0] ?? 0}
              max={default_values?.price_per_meter_monthly?.[1] ?? 0}
              values={[
                price_per_meter_monthly_range?.split("-")[0],
                price_per_meter_monthly_range?.split("-")[1],
              ]}
              target="price_per_meter_monthly_range"
              unit={currency_icon}
            />

            <p className="label">{t("filters.Yearly rent")}</p>
            <Slider
              min={default_values?.price_per_meter_yearly?.[0] ?? 0}
              max={default_values?.price_per_meter_yearly?.[1] ?? 0}
              values={[
                price_per_meter_yearly_range?.split("-")[0],
                price_per_meter_yearly_range?.split("-")[1],
              ]}
              target="price_per_meter_yearly_range"
              unit={currency_icon}
            />

            <p className="label">{t("filters.Payment method")}</p>
            <div className="checkbox-container">
              <Checkbox
                label={t("filters.Yearly")}
                checked={payment_method === "yearly" ? true : false}
                onClick={() => handleBtnClick("payment_method", "yearly")}
              />
              <Checkbox
                label={t("filters.3 Months")}
                checked={payment_method === "quaterly" ? true : false}
                onClick={() => handleBtnClick("payment_method", "quaterly")}
              />
            </div>
            <div className="checkbox-container">
              <Checkbox
                label={t("filters.6 Months")}
                checked={payment_method === "halfly" ? true : false}
                onClick={() => handleBtnClick("payment_method", "halfly")}
              />

              <Checkbox
                label={t("filters.Monthly")}
                checked={payment_method === "monthly" ? true : false}
                onClick={() => handleBtnClick("payment_method", "monthly")}
              />
            </div>
          </>
        )
      )}

      {filters.offer_type === "daily_rent" && <DailyRentPrices />}
    </div>
  );
};

export default Pricing;
