import { Outlet } from "react-router-dom";
import Backdrop from "../../components/Backdrop";
import Footer from "../../components/Footer/Footer";
import GuestGuard from "../../components/GuestGuard";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

const GuestLayout = () => {
  return (
    <GuestGuard>
      <div className="main_layout">
        <Sidebar />
        <div className="main_layout__container">
          <Header />
          <Outlet />
          <Footer />
        </div>
        <Backdrop />
      </div>
    </GuestGuard>
  );
};

export default GuestLayout;
