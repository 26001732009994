import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import publicAxios from "../../utils/publicAxios";

const initialState = {
  error: null,
  loading: "idle",
};

export const sendEamil = createAsyncThunk("api/email", async (values) => {
  try {
    await axiosInstance.post(`/home/contactus`, values);
  } catch (err) {
    const error = err;
    return Promise.reject(
      error.message ? error.message : "Something went wrong!"
    );
  }
});

export const emailInquiry = createAsyncThunk(
  "api/emailInquiry",
  async ({ email, lang }) => {
    try {
      let data;

      const response = await axiosInstance.post(
        `/home/add_email_inquiry?lang=${lang}`,
        {
          email,
        }
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(
        error.message ? error.message : "Something went wrong!"
      );
    }
  }
);

export const contactSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {},
  extraReducers: {
    [sendEamil.pending]: (state) => {
      state.loading = "loading";
    },
    [sendEamil.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [sendEamil.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const {} = contactSlice.actions;

export default contactSlice.reducer;
