import { useTranslation } from "react-i18next";
import Checkbox from "../Inputs/Checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  handleStateChange,
  handleStateChangeMultiple,
} from "../../../../data/slices/filtersSlice";

const Services = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    water_service,
    electricity_service,
    guard_service,
    reception_service,
    landline,
    internet_can_install,
    internet_available,
  } = useSelector((state) => state.filters);

  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
  };

  const handleBtnClickMultiple = (target, value) => {
    dispatch(
      handleStateChangeMultiple({
        target,
        value,
      })
    );
  };

  const { specifications } = useSelector((state) => state.properties);

 

  return (
    <div className="services-filters">
        <div className="smaller-content-container">
          <p className="label">{t("filters.Electricity")}</p>
          <div className="btns-list">
            <button
              className={`btn ${
                electricity_service?.includes("separate_meter") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("electricity_service", "separate_meter")
              }
            >
              {t("filters.Seperate_meter")}
            </button>
            <button
              className={`btn ${
                electricity_service?.includes("shared_meter") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("electricity_service", "shared_meter")
              }
            >
              {t("filters.Shared_meter")}
            </button>
            <button
              className={`btn ${
                electricity_service?.includes("not_available") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("electricity_service", "not_available")
              }
            >
              {t("filters.not_available")}
            </button>
            <button
              className={`btn ${
                electricity_service?.includes("available_by_tanker") &&
                "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple(
                  "electricity_service",
                  "available_by_tanker"
                )
              }
            >
              {t("filters.available_by_generator")}
            </button>
          </div>
        </div>
      

        <div className="smaller-content-container">
          <p className="label">{t("filters.Water")}</p>
          <div className="btns-list">
            <button
              className={`btn ${
                water_service?.includes("separate_meter") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("water_service", "separate_meter")
              }
            >
              {t("filters.seperate_meter")}
            </button>
            <button
              className={`btn ${
                water_service?.includes("shared_meter") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("water_service", "shared_meter")
              }
            >
              {t("filters.shared_meter")}
            </button>
            <button
              className={`btn ${
                water_service?.includes("not_available") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("water_service", "not_available")
              }
            >
              {t("filters.not_available")}
            </button>

            <button
              className={`btn ${
                water_service?.includes("available_by_tanker") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("water_service", "available_by_tanker")
              }
            >
              {t("filters.available_by_tanker")}
            </button>
          </div>
        </div>
      
        <div className="smaller-content-container">
          <p className="label">{t("filters.Internet_available")} :</p>
          <div className="btns-list">
            <button
              className={`btn ${
                internet_available?.includes("Internet Fiber Free") &&
                "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple(
                  "internet_available",
                  "Internet Fiber Free"
                )
              }
            >
              {t("filters.Internet Fiber Free")}
            </button>
            <button
              className={`btn ${
                internet_available?.includes("Internet DSL Free") &&
                "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple(
                  "internet_available",
                  "Internet DSL Free"
                )
              }
            >
              {t("filters.Internet DSL Free")}
            </button>
            <button
              className={`btn ${
                internet_available?.includes("Internet 4G Free") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("internet_available", "Internet 4G Free")
              }
            >
              {t("filters.Internet 4G Free")}
            </button>
            <button
              className={`btn ${
                internet_available?.includes("Internet Fiber with cost") &&
                "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple(
                  "internet_available",
                  "Internet Fiber with cost"
                )
              }
            >
              {t("filters.Internet Fiber with cost")}
            </button>
            <button
              className={`btn ${
                internet_available?.includes("Internet DSL with cost") &&
                "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple(
                  "internet_available",
                  "Internet DSL with cost"
                )
              }
            >
              {t("filters.Internet DSL with cost")}
            </button>
            <button
              className={`btn ${
                internet_available?.includes("Internet 4G with cost") &&
                "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple(
                  "internet_available",
                  "Internet 4G with cost"
                )
              }
            >
              {t("filters.Internet 4G with cost")}
            </button>
          </div>
        </div>
      

        <div className="smaller-content-container">
          <p className="label">{t("filters.internet_can_install")} :</p>
          <div className="btns-list">
            <button
              className={`btn ${
                internet_can_install?.includes("Internet DSL can install") &&
                "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple(
                  "internet_can_install",
                  "Internet DSL can install"
                )
              }
            >
              {t("filters.Internet DSL can install")}
            </button>

            <button
              className={`btn ${
                internet_can_install?.includes("Internet 4G can install") &&
                "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple(
                  "internet_can_install",
                  "Internet 4G can install"
                )
              }
            >
              {t("filters.Internet 4G can install")}
            </button>

            <button
              className={`btn ${
                internet_can_install?.includes("Internet Fiber can install") &&
                "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple(
                  "internet_can_install",
                  "Internet Fiber can install"
                )
              }
            >
              {t("filters.Internet Fiber can install")}
            </button>
          </div>
        </div>
      

        <div className="smaller-content-container">
          <p className="label">{t("filters.Land_line")}</p>
          <div className="btns-list">
            <button
              className={`btn ${
                landline?.includes("available_free") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("landline", "available_free")
              }
            >
              {t("filters.Available_free")}
            </button>

            <button
              className={`btn ${
                landline?.includes("available_by_fees") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("landline", "available_by_fees")
              }
            >
              {t("filters.available_by_fees")}
            </button>

            <button
              className={`btn ${
                landline?.includes("can_be_installed") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("landline", "can_be_installed")
              }
            >
              {t("filters.can_be_installed")}
            </button>

            {/* <button
              className={`btn ${
                landline?.includes("cant_be_installed") && "btn-active"
              }`}
              onClick={() =>
                handleBtnClickMultiple("landline", "cant_be_installed")
              }
            >
              {t("filters.cant_be_installed")}
            </button> */}
          </div>
        </div>
      

      <div className="checkbox-container">

          <Checkbox
            label={t("filters.Guard_services")}
            checked={guard_service === "yes" ? true : false}
            onClick={() =>
              handleBtnClick(
                "guard_service",
                guard_service === "yes" ? "" : "yes"
              )
            }
          />
        
          <Checkbox
            label={t("filters.Reception_services")}
            checked={reception_service === "yes" ? true : false}
            onClick={() =>
              handleBtnClick(
                "reception_service",
                reception_service === "yes" ? "" : "yes"
              )
            }
          />
        
      </div>
    </div>
  );
};

export default Services;
