import { useState } from 'react';
import { Slider as MUISlider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { handleStateChange } from '../../../../../data/slices/filtersSlice';

const YearSlider = ({ min, max, values, target }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!values[0]) values[0] = min;
  if (!values[1]) values[1] = max;

  const minDistance = 1;

  function valuetext(value) {
    return `${value}`;
  }

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (typeof target === 'string') {
      if (activeThumb === 0) {
        dispatch(
          handleStateChange({
            target: target,
            value: String(newValue[0] + '-' + values[1]),
          })
        );
      } else {
        dispatch(
          handleStateChange({
            target: target,
            value: String(values[0] + '-' + newValue[1]),
          })
        );
      }

      return;
    }

    if (activeThumb === 0) {
      dispatch(
        handleStateChange({
          target: target[0],
          value: String(Math.min(newValue[0], values[1] - minDistance)),
        })
      );
    } else {
      dispatch(
        handleStateChange({
          target: target[1],
          value: String(Math.max(newValue[1], values[0] + minDistance)),
        })
      );
    }
  };

  return (
    <div className='slider-input'>
      <MUISlider
        value={values}
        valueLabelDisplay='auto'
        getAriaValueText={valuetext}
        disableSwap
        min={min}
        max={max}
        onChange={handleChange}
      />
      <div className='slider-values-container'>
        <span>
          {new Intl.NumberFormat().format(values[0])} {t('filters.years')}
        </span>
        <span>
          {new Intl.NumberFormat().format(values[1])} {t('filters.years')}
        </span>
      </div>
    </div>
  );
};

export default YearSlider;
