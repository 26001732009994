import { configureStore } from "@reduxjs/toolkit";
import exampleReducer from "./slices/exampleSlice";
import authReducer from "./slices/authSlice";
import { reducer as modalsReducer } from "./slices/modals";
import settingsReducer from "./slices/settingsSlice";
import countryReducer from "./slices/country";
import filtersReducer from "./slices/filtersSlice";
import tabsReducer from "./slices/tabsSlice";
import propertiesReducer from "./slices/propertiesSlice";
import favoriteReducer from "./slices/favoriteSlice";
import brokersReducer from "./slices/brokersSlice";
import ownPropertiesReducer from "./slices/ownPropertiesSlice";
import contactReducer from "./slices/contactSlice";
import brokerFiltersReducer from "./slices/brokerFilters";
import callMsgCounterReducer from "./slices/callMsgCounterSlice";

export const store = configureStore({
  reducer: {
    example: exampleReducer,
    auth: authReducer,
    modals: modalsReducer,
    settings: settingsReducer,
    countries: countryReducer,
    filters: filtersReducer,
    tabs: tabsReducer,
    properties: propertiesReducer,
    favorite: favoriteReducer,
    brokers: brokersReducer,
    ownProperties: ownPropertiesReducer,
    contact: contactReducer,
    brokerFilters: brokerFiltersReducer,
    callMsgCount: callMsgCounterReducer,
  },
});
