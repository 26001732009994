import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  currentTab: "Sale",
};

export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    changeTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
});

export const { changeTab } = tabsSlice.actions;

export default tabsSlice.reducer;
