import { useEffect } from "react";
import RoutesProvider from "./routes";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "./data/slices/settingsSlice";


function App() {
  const dispatch = useDispatch();
  const { direction } = useSelector((state) => state.settings);

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    dispatch(setLang(currentLanguage.dir || "ltr"));
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const emptyCache = createCache({
    key: "meaningless-key",
  });

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const theme = createTheme({
    direction: direction,
  });

  

  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={direction === "ltr" ? emptyCache : cacheRtl}>
        <RoutesProvider />
      </CacheProvider>
    </ThemeProvider>
  );
}

export default App;
