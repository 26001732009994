import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterCountry,
  setFilterCountryID,
} from "../data/slices/filtersSlice";
import { setCountryInfo } from "../data/slices/brokerFilters";
const CountryProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.countries);
  const [countryCode, setCountryCode] = useState();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const filters = useSelector((state) => state.filters);

  const isCountryCodeValid = (countryIsoCode) => {
    return countries.some(
      (country) => country?.countries_iso_code == countryIsoCode
    );
  };

  const isCountryCodeValidNoAuth = (countryIsoCode) => {
    const validCountry = countries.find(
      (country) => country?.countries_iso_code == countryIsoCode
    );

    return {
      isValid: validCountry !== undefined,
      countryId: validCountry?.country_id || null,
    };
  };

  useEffect(() => {
    if (!isAuthenticated) {
      // fetch("https://ip-api.com/json")
      fetch("https://ip.nf/me.json")
        .then((response) => response.json())
        .then((data) => {
          setCountryCode(data?.ip?.country_code);
        });
    }
  }, []);

  useEffect(() => {
    //Important : when user set the country from select
    if (filters?.country_id?.length > 0) {
      return;
    }
    //when user not logged in

    if (!isAuthenticated) {
      //wait for the country info to be here
      if (!user && countryCode) {
        //if the not logged user country exist in our system
        let validity = isCountryCodeValidNoAuth(countryCode);
        if (validity?.isValid) {
          dispatch(setFilterCountry(countryCode));
          dispatch(setFilterCountryID(validity?.countryId));
          //broker list
          // dispatch(
          //   setCountryInfo({
          //     country_id: validity?.countryId,
          //     country_code: countryCode,
          //   })
          // );
        } else {
          dispatch(setFilterCountry("SA"));
          dispatch(dispatch(setFilterCountryID("184")));
          //broker
          // dispatch(
          //   setCountryInfo({
          //     country_id: "184",
          //     country_code: "SA",
          //   })
          // );
        }
      } else {
        dispatch(setFilterCountry("SA"));
        dispatch(dispatch(setFilterCountryID("184")));
        //broker list
        // dispatch(
        //   setCountryInfo({
        //     country_id: "184",
        //     country_code: "SA",
        //   })
        // );
      }
    } else {
      //USER IS LOGGED IN and if there is countries
      if (!countries || countries.length === 0) {
        return;
      }
      if (countries?.length > 0) {
        //if user country exist in our countries list
        if (isCountryCodeValid(user?.country_iso_code)) {
          dispatch(setFilterCountry(user?.country_iso_code));
          dispatch(setFilterCountryID(user?.country_id));
          //broker list
          // dispatch(
          //   setCountryInfo({
          //     country_id: user?.country_id,
          //     country_code: user?.country_iso_code,
          //   })
          // );
        } else {
          if (filters?.country_id?.length > 0) {
            return;
          } else {
            dispatch(setFilterCountry("SA"));
            dispatch(dispatch(setFilterCountryID("184")));
          }

          //broker list
          // dispatch(
          //   setCountryInfo({
          //     country_id: "184",
          //     country_code: "SA",
          //   })
          // );
        }
      }
    }
  }, [countryCode, isAuthenticated, countries]);

  return <>{children}</>;
};

export default CountryProvider;
