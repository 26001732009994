import Slider from "../Inputs/Slider/Slider";
import Checkbox from "../Inputs/Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  handleStateChange,
  handleStateChangeMultiple,
} from "../../../../data/slices/filtersSlice";

const Rooms = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    majles,
    storeroom,
    extension,
    roof_apartment,
    bedrooms,
    bathrooms,
    halls,
    open_kitchen,
    saperate_kitchen,
    car_park,
    maid_room,
    driver_room,
    football_stadium,
    swimming_pool,
    frontyard,
    frontyard_range,
    backyard,
    backyard_range,
    sideyard,
    sideyard_range,
    grass_area,
    grass_area_range,
    tent,
    tent_range,
    fixed_caravan,
    fixed_caravan_range,
    total_rooms_range,
  } = useSelector((state) => state.filters);

  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
  };

  const handleBtnClickMultiple = (target, value) => {
    dispatch(
      handleStateChangeMultiple({
        target,
        value,
      })
    );
  };

  const { specifications, default_values } = useSelector(
    (state) => state.properties
  );

  return (
    <div className="rooms-filters">
      <div className="smaller-content-container">
        <p className="label">{t("filters.Total_rooms")}</p>
        <Slider
          min={default_values?.total_rooms?.[0] ?? 0}
          max={default_values?.total_rooms?.[1] ?? 0}
          values={[
            total_rooms_range?.split("-")[0],
            total_rooms_range?.split("-")[1],
          ]}
          target="total_rooms_range"
          unit=""
        />
      </div>

      <div className="smaller-content-container">
        <p className="label">{t("filters.Bedrooms")}</p>
        <div className="btns-list">
          {/* <button
						className={`btn ${bedrooms?.includes('studio') && 'btn-active'}`}
						onClick={() => handleBtnClickMultiple('bedrooms', 'studio')}
					>
						{t('filters.studio')}
					</button> */}
          <button
            className={`btn ${bedrooms?.includes("1") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("bedrooms", "1")}
          >
            {t("filters.One_Bedroom")}
          </button>
          <button
            className={`btn ${bedrooms?.includes("2") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("bedrooms", "2")}
          >
            {t("filters.Two_bedrooms")}
          </button>

          <button
            className={`btn ${bedrooms?.includes("3") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("bedrooms", "3")}
          >
            {t("filters.Three_Bedrooms")}
          </button>
          <button
            className={`btn ${bedrooms?.includes("more") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("bedrooms", "more")}
          >
            {t("filters.More_than_three_Bedrooms")}
          </button>
        </div>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Bathrooms")}</p>
        <button
          className={`btn ${bathrooms?.includes("1") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("bathrooms", "1")}
        >
          1
        </button>
        <button
          className={`btn ${bathrooms?.includes("2") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("bathrooms", "2")}
        >
          2
        </button>
        <button
          className={`btn ${bathrooms?.includes("3") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("bathrooms", "3")}
        >
          3
        </button>
        <button
          className={`btn ${bathrooms?.includes("more") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("bathrooms", "more")}
        >
          {t("filters.More_than_3")}
        </button>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Halls")}</p>
        <button
          className={`btn ${halls?.includes("1") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("halls", "1")}
        >
          {t("filters.One_Hall")}
        </button>
        <button
          className={`btn ${halls?.includes("more") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("halls", "more")}
        >
          {t("filters.More_than_one_hall")}
        </button>
      </div>

      <div className="smaller-content-container">
        <p className="label">{t("filters.Kitchen")}</p>
        <div className="btns-list">
          {specifications?.open_kitchen === "1" && (
            <button
              className={`btn ${open_kitchen?.includes("1") && "btn-active"}`}
              onClick={() => handleBtnClickMultiple("open_kitchen", "1")}
            >
              {t("filters.One_opened_Kitchen")}
            </button>
          )}
          {specifications?.saperate_kitchen === "1" && (
            <button
              className={`btn ${
                saperate_kitchen?.includes("1") && "btn-active"
              }`}
              onClick={() => handleBtnClickMultiple("saperate_kitchen", "1")}
            >
              {t("filters.one_separate_kitchen")}
            </button>
          )}
          {specifications?.open_kitchen === "1" && (
            <button
              className={`btn ${
                open_kitchen?.includes("more") && "btn-active"
              }`}
              onClick={() => handleBtnClickMultiple("open_kitchen", "more")}
            >
              {t("filters.more_than_1_opened_kitchen")}
            </button>
          )}
          {specifications?.saperate_kitchen === "1" && (
            <button
              className={`btn ${
                saperate_kitchen?.includes("more") && "btn-active"
              }`}
              onClick={() => handleBtnClickMultiple("saperate_kitchen", "more")}
            >
              {t("filters.more_than_1_seperate_kitchen")}
            </button>
          )}
        </div>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Majles")}</p>
        <button
          className={`btn ${majles?.includes("1") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("majles", "1")}
        >
          {t("filters.one_majles")}
        </button>
        <button
          className={`btn ${majles?.includes("more") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("majles", "more")}
        >
          {t("filters.More_than_one_majles")}
        </button>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Storerooms")}</p>
        <button
          className={`btn ${storeroom?.includes("1") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("storeroom", "1")}
        >
          {t("filters.one_storeroom")}
        </button>
        <button
          className={`btn ${storeroom?.includes("more") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("storeroom", "more")}
        >
          {t("filters.more_than_1_storeroom")}
        </button>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Extension")}</p>
        <button
          className={`btn ${extension?.includes("1") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("extension", "1")}
        >
          {t("filters.One_extension")}
        </button>
        <button
          className={`btn ${extension?.includes("more") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("extension", "more")}
        >
          {t("filters.more_than_1_extension")}
        </button>
      </div>

      <div className="btns-list">
        <p className="label">{t("filters.Roof_apartment")}</p>
        <button
          className={`btn ${roof_apartment?.includes("1") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("roof_apartment", "1")}
        >
          {t("filters.One_Roof_apartment")}
        </button>
        <button
          className={`btn ${roof_apartment?.includes("more") && "btn-active"}`}
          onClick={() => handleBtnClickMultiple("roof_apartment", "more")}
        >
          {t("filters.more_than_one")}
        </button>
      </div>

      {/* <div className='btns-list'>
				<p className='label'>{t('filters.Outdoor_patio')}</p>
				<button
					className={`btn ${
						outdoor_patio_square_meter_range?.split('-')[1] > '0' ? 'btn-active' : ''
					}`}
					onClick={() => handleBtnClick('outdoor_patio_square_meter_range', '0-2')}
				>
					{t('filters.with_outdoor_patio')}
				</button>
				<button
					className={`btn ${
						outdoor_patio_square_meter_range?.split('-')[0] === '0' &&
						outdoor_patio_square_meter_range?.split('-')[1] === '0'
							? 'btn-active'
							: ''
					}`}
					onClick={() => handleBtnClick('outdoor_patio_square_meter_range', '0-0')}
				>
					{t('filters.without_outdoor_patio')}
				</button>
			</div> */}

      {/* <div className='smaller-content-container'>
				<p className='label'>{t('filters.Outdoor_patio_by_square_meter')}</p>
				<Slider
					min={0}
					max={124}
					values={[
						outdoor_patio_square_meter_range?.split('-')[0],
						outdoor_patio_square_meter_range?.split('-')[1],
					]}
					target='outdoor_patio_square_meter_range'
					unit=''
				/>
			</div> */}

      <div className="smaller-content-container">
        <div className="btns-list">
          <p className="label">{t("filters.frontyard")}</p>
          <button
            className={`btn ${frontyard?.includes("yes") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("frontyard", "yes")}
          >
            {t("filters.With_frontyard")}
          </button>
          <button
            className={`btn ${frontyard?.includes("no") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("frontyard", "no")}
          >
            {t("filters.without_frontyard")}
          </button>
        </div>
        {frontyard?.includes("yes") && (
          <div className="smaller-content-container">
            <p className="label">{t("filters.frontyard_range")}</p>
            <Slider
              min={default_values?.frontyard?.[0] ?? 0}
              max={default_values?.frontyard?.[1] ?? 0}
              values={[
                frontyard_range?.split("-")[0],
                frontyard_range?.split("-")[1],
              ]}
              target="frontyard_range"
              unit=""
            />
          </div>
        )}
      </div>

      <div className="smaller-content-container">
        <div className="btns-list">
          <p className="label">{t("filters.backyard")}</p>
          <button
            className={`btn ${backyard?.includes("yes") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("backyard", "yes")}
          >
            {t("filters.With_backyard")}
          </button>
          <button
            className={`btn ${backyard?.includes("no") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("backyard", "no")}
          >
            {t("filters.without_backyard")}
          </button>
        </div>
        {backyard?.includes("yes") && (
          <div className="smaller-content-container">
            <p className="label">{t("filters.backyard_range")}</p>
            <Slider
              min={default_values?.backyard?.[0] ?? 0}
              max={default_values?.backyard?.[1] ?? 0}
              values={[
                backyard_range?.split("-")[0],
                backyard_range?.split("-")[1],
              ]}
              target="backyard_range"
              unit=""
            />
          </div>
        )}
      </div>

      <div className="smaller-content-container">
        <div className="btns-list">
          <p className="label">{t("filters.sideyard")}</p>
          <button
            className={`btn ${sideyard?.includes("yes") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("sideyard", "yes")}
          >
            {t("filters.With_sideyard")}
          </button>
          <button
            className={`btn ${sideyard?.includes("no") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("sideyard", "no")}
          >
            {t("filters.without_sideyard")}
          </button>
        </div>
        {sideyard?.includes("yes") && (
          <div className="smaller-content-container">
            <p className="label">{t("filters.sideyard_range")}</p>
            <Slider
              min={default_values?.sideyard?.[0] ?? 0}
              max={default_values?.sideyard?.[1] ?? 0}
              values={[
                sideyard_range?.split("-")[0],
                sideyard_range?.split("-")[1],
              ]}
              target="sideyard_range"
              unit=""
            />
          </div>
        )}
      </div>

      <div className="smaller-content-container">
        <div className="btns-list">
          <p className="label">{t("filters.Grass_area")}</p>
          <button
            className={`btn ${grass_area?.includes("yes") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("grass_area", "yes")}
          >
            {t("filters.with_grass_area")}
          </button>
          <button
            className={`btn ${grass_area?.includes("no") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("grass_area", "no")}
          >
            {t("filters.without_grass_area")}
          </button>
        </div>
        {grass_area?.includes("yes") && (
          <div className="smaller-content-container">
            <p className="label">{t("filters.grass_area_range")}</p>
            <Slider
              min={default_values?.grass_area?.[0] ?? 0}
              max={default_values?.grass_area?.[1] ?? 0}
              values={[
                grass_area_range?.split("-")[0],
                grass_area_range?.split("-")[1],
              ]}
              target="grass_area_range"
              unit=""
            />
          </div>
        )}
      </div>

      <div className="smaller-content-container">
        <div className="btns-list">
          <p className="label">{t("filters.Tents")}</p>
          <button
            className={`btn ${tent?.includes("yes") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("tent", "yes")}
          >
            {t("filters.with_tent")}
          </button>
          <button
            className={`btn ${tent?.includes("no") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("tent", "no")}
          >
            {t("filters.without_tent")}
          </button>
        </div>
        {tent?.includes("yes") && (
          <div className="smaller-content-container">
            <p className="label">{t("filters.tent_range")}</p>
            <Slider
              min={default_values?.tent?.[0] ?? 0}
              max={default_values?.tent?.[1] ?? 0}
              values={[tent_range?.split("-")[0], tent_range?.split("-")[1]]}
              target="tent_range"
              unit=""
            />
          </div>
        )}
      </div>

      <div className="smaller-content-container">
        <div className="btns-list">
          <p className="label">{t("filters.Fixed_caravan")}</p>
          <button
            className={`btn ${fixed_caravan?.includes("yes") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("fixed_caravan", "yes")}
          >
            {t("filters.with_fixed_caravan")}
          </button>
          <button
            className={`btn ${fixed_caravan?.includes("no") && "btn-active"}`}
            onClick={() => handleBtnClickMultiple("fixed_caravan", "no")}
          >
            {t("filters.without_fixed_caravan")}
          </button>
        </div>
        {fixed_caravan?.includes("yes") && (
          <div className="smaller-confixed_caravan-container">
            <p className="label">{t("filters.fixed_caravan_range")}</p>
            <Slider
              min={default_values?.fixed_caravan?.[0] ?? 0}
              max={default_values?.fixed_caravan?.[1] ?? 0}
              values={[
                fixed_caravan_range?.split("-")[0],
                fixed_caravan_range?.split("-")[1],
              ]}
              target="fixed_caravan_range"
              unit=""
            />
          </div>
        )}
      </div>

      <div className="checkbox-container">
        <Checkbox
          label={t("filters.Swimming_pool")}
          checked={swimming_pool === "yes" ? true : false}
          onClick={() =>
            handleBtnClick(
              "swimming_pool",
              swimming_pool === "yes" ? "no" : "yes"
            )
          }
        />
        <Checkbox
          label={t("filters.Maid_room")}
          checked={maid_room === "yes" ? true : false}
          onClick={() =>
            handleBtnClick("maid_room", maid_room === "yes" ? "no" : "yes")
          }
        />
      </div>

      <div className="checkbox-container">
        <Checkbox
          label={t("filters.Football_stadium")}
          checked={football_stadium === "yes" ? true : false}
          onClick={() =>
            handleBtnClick(
              "football_stadium",
              football_stadium === "yes" ? "no" : "yes"
            )
          }
        />

        <Checkbox
          label={t("filters.car_park")}
          checked={car_park === "yes" ? true : false}
          onClick={() =>
            handleBtnClick("car_park", car_park === "yes" ? "no" : "yes")
          }
        />
      </div>

      <div className="checkbox-container">
        <Checkbox
          label={t("filters.Drivers_room")}
          checked={driver_room === "yes" ? true : false}
          onClick={() =>
            handleBtnClick("driver_room", driver_room === "yes" ? "no" : "yes")
          }
        />
      </div>
    </div>
  );
};

export default Rooms;
