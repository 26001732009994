import axios from "axios";
export const headers = {
  Accept: "application/json",
  "content-type": "application/json; charset=UTF-8",
  "x-api-key": "1234",
};
const publicAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
});

export default publicAxios;
