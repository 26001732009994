import React from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
const RemoveFavoriteModal = ({
  setIsRemoveFavoritOpen,
  handleFavoriteFunction,
}) => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  return (
    <div className="remove-fav-container">
      <span className="title">{t("remove_favorite")}</span>
      <span className="description">{t("remove_favorite_description")}</span>
      <div className="modal-buttons-container">
        <button
          className="delete-button"
          onClick={() => handleFavoriteFunction()}
        >
         
          {t("delete_btn")}
        </button>
        <button
          className="cancel-button"
          onClick={() => setIsRemoveFavoritOpen(false)}
        >
          
          {t("cancel_btn")}
        </button>
      </div>
    </div>
  );
};

export default RemoveFavoriteModal;
