const Checkbox = ({ label, name, value, checked = false, onClick }) => {
  return (
    <div
      className="checkbox-input"
      onClick={(e) => {
        e.preventDefault();
        onClick({ target: { name, checked: !checked } });
      }}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        value={value}
        checked={checked}
      />
      <label for={name}>{label}</label>
    </div>
  );
};

export default Checkbox;
