import { useSelector, useDispatch } from 'react-redux';
import { closeSidebar } from '../../data/slices/settingsSlice';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import useWindowSize from '../../hooks/useWindowSize';
import Navigation from '../Navigation/Navigation';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Logo from '../Logo/Logo';

const CloseSidebarTrigger = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  if (width >= 1090) return null;
  return (
    <div className='close_sidebar_trigger' onClick={() => dispatch(closeSidebar())}>
      <CloseIcon />
    </div>
  );
};

const Sidebar = () => {
  const { isSidebarOpened } = useSelector((state) => state.settings);
  const { width } = useWindowSize();
  const { t } = useTranslation();

  if (width > 1090) {
    return null;
  }
  const currentLanguageCode = cookies.get('i18next') || 'en';

  return (
    <div
      className={
        isSidebarOpened === true && width < 1090 && currentLanguageCode === 'en'
          ? 'sidebar open'
          : isSidebarOpened === true && width < 1090 && currentLanguageCode === 'ar'
          ? 'sidebar_ar open_ar'
          : currentLanguageCode === 'en'
          ? 'sidebar'
          : currentLanguageCode === 'ar' && 'sidebar_ar'
      }
    >
      <div className='sidebar__group'>
        <Navigation />
      </div>
    </div>
  );
};

export default Sidebar;
