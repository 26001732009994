import { Modal } from "antd";
import "./_PropertyModal.scss";
import Card from "../../../../ListSale/components/Card/Card";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { useLocation } from "react-router-dom";
const PropertyModal = ({ id, open, handleClose, data, ...rest }) => {
  // console.log(data);
  const location = useLocation();

  const { width } = useWindowSize();
  if (width > 600) {
    return null;
  }
  return (
    <Modal
      open={open}
      closeIcon={false}
      wrapClassName="property_modal"
      footer={null}
      maskClosable={true} // Allow closing when clicking outside
      width={"100%"}
      onCancel={() => handleClose(id)} // You might want to add this for the close button
    >
      <Card
        propertieItem={data?.property}
        favorite={location.pathname.includes("favorites")}
      />
      {/* <div className="close_icon">close</div> */}
    </Modal>
  );
};
export default PropertyModal;
