import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import cookies from "js-cookie";

const lang = cookies.get("i18next") || "en";
const initialState = {
  brokers: [],
  searchInput: "",
  addresses: [],
  error: null,
  message: null,
  loading: "idle",
};

export const getBrokers = createAsyncThunk(
  "api/brokers",
  async ({ filter, lang }) => {
    let data;
    try {
      // Convert the filter object to query string
      const queryString = !filter
        ? ""
        : Object.keys(filter)
            .map((key) => `${key}=${encodeURIComponent(filter[key] || "")}`)
            .join("&");

      const response = await axiosInstance.get(
        `/home/getsellingagentinfo?lang=${lang}&${queryString}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const brokersSlice = createSlice({
  name: "brokers",
  initialState,
  reducers: {
    setSearchInput: (state, action) => {
      state.searchInput = action.payload;
    },
  },
  extraReducers: {
    [getBrokers.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getBrokers.fulfilled]: (state, action) => {
      const { selling_agent } = action.payload;
      if (selling_agent) {
        state.addresses = selling_agent?.map((broker) => {
          return broker.latitude_longitude;
        });
        state.brokers = selling_agent;
      } else {
        state.brokers = [];
      }

      state.loading = "idle";
    },
    [getBrokers.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = "error";
    },
  },
});

export const { setSearchInput } = brokersSlice.actions;

export default brokersSlice.reducer;
