import "./_Spinner.scss";
function Spinner() {
  return (
    <div className="spinner_p">
      <span className="loader">L &nbsp; ading</span>
    </div>
  );
}

export default Spinner;
